// components/Tiles/components/ListGrid/Cart/Modal/modalContext.tsx
import React, { createContext, useState, ReactNode } from "react";

type PaymentScheduleItem = {
  title?: string;
  subTitle?: string;
  deliverables?: string[];
  paymentAmount?: string;
  paymentTrigger?: string;
};

interface ModalContextType {
  showCheckoutForm: boolean;
  setShowCheckoutForm: (show: boolean) => void;
  clientSecret: string;
  setClientSecret: (clientSecret: string) => void;
  paymentSchedule: PaymentScheduleItem[] | null;
  setPaymentSchedule: (schedule: PaymentScheduleItem[] | null) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  paymentMethod: string;
  setPaymentMethod: (paymentMethod: string) => void;
}

export const ModalContext = createContext<ModalContextType>({
  showCheckoutForm: false,
  setShowCheckoutForm: () => {},
  clientSecret: "",
  setClientSecret: () => {},
  paymentSchedule: null,
  setPaymentSchedule: () => {},
  currentStep: 0,
  setCurrentStep: () => {},
  paymentMethod: "",
  setPaymentMethod: () => {}
});

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [showCheckoutForm, setShowCheckoutForm] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [paymentSchedule, setPaymentSchedule] = useState<PaymentScheduleItem[] | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const modalContextValues: ModalContextType = {
    showCheckoutForm,
    setShowCheckoutForm,
    clientSecret,
    setClientSecret,
    paymentSchedule,
    paymentMethod,
    setPaymentMethod,
    setPaymentSchedule,
    currentStep,
    setCurrentStep // Ensure this line is present
  };

  return <ModalContext.Provider value={modalContextValues}>{children}</ModalContext.Provider>;
};
