import React, { useContext } from "react";
import { ShoppingCart, CalendarCheck2, CreditCard, ChevronRight, CheckCircle } from "lucide-react";
import clsx from "clsx";
import { ModalContext } from "context/modalContext";

const StepNavigation: React.FC = () => {
  const { currentStep, setCurrentStep } = useContext(ModalContext);

  const steps = [
    { name: "Cart", icon: ShoppingCart },
    { name: "Schedule", icon: CalendarCheck2 },
    { name: "Payment Details", icon: CreditCard },
    { name: "Complete", icon: CheckCircle }
  ];

  const navigateToStep = (stepIndex: number) => {
    if (stepIndex <= currentStep) {
      setCurrentStep(stepIndex);
    }
  };

  return (
    <div className="step-navigation ">
      {steps.map((step, index) => (
        <React.Fragment key={step.name}>
          <button
            type="button"
            onClick={() => navigateToStep(index)}
            disabled={index > currentStep}
            className={clsx(index === currentStep && "current-step", index > currentStep && "!bg-transparent")}
          >
            <step.icon
              className={`mr-2 inline-block h-5 w-5 ${index === currentStep ? "text-primary-700" : "text-gray-500"}`}
            />
          </button>
          {index < steps.length - 1 && (
            <span className="mx-2">
              <ChevronRight size="16" className="inline-flex text-primary-700" />
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepNavigation;
