// components/Tiles/components/ListGrid/Cart/Modal/stepCheckout.tsx
"use client";

import React, { useContext, RefObject } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ModalContext } from "context/modalContext";
import CheckoutFormSidebar from "./checkoutForm";
import StepLayout from "./StepLayout";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "");

type StepCheckoutProps = {
  formRef: RefObject<HTMLFormElement>;
  onNext: () => void; // Add onNext prop
};

const StepCheckout: React.FC<StepCheckoutProps> = ({ formRef, onNext }) => {
  const { showCheckoutForm, clientSecret } = useContext(ModalContext);
  const [isLoading, setIsLoading] = React.useState(false); // State to manage loading

  const appearance = {
    theme: "stripe",
    variables: {
      colorText: "#183837",
      fontFamily: "matter, sans-serif",
      spacingUnit: "6px",
      colorPrimary: "#017848",
      fontWeightBold: "400",
      fontWeightMedium: "400",
      colorDanger: "f43f5e",
      gridRowSpacing: "30px"
    }
  } as const;

  if (!showCheckoutForm || !clientSecret) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <StepLayout onNext={onNext} isLoading={isLoading} buttonText="Pay Now">
        <CheckoutFormSidebar formRef={formRef} setIsLoading={setIsLoading} />
      </StepLayout>
    </Elements>
  );
};

export default StepCheckout;
