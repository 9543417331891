// context/CartContext.tsx
"use client";

import React, { createContext, useReducer, ReactNode, useEffect, useState, useMemo } from "react";
import { ModalProvider } from "./modalContext";

type ItemEntry = {
  key: string;
  value: any;
};

export type CartItem = {
  name?: string;
  id?: string;
  useGroupCard?: boolean;
  groupCardKey?: string;
  cost_line_ids?: number[];
  image?: string;
  total?: number | undefined;
  original?: number | undefined;
  grandTotal?: number | undefined;
  quantity?: string;
  description?: string;
  items: ItemEntry[];
};

export type CartContextType = {
  cartItems: CartItem[];
  tile_id?: string;
  scope_id?: string;
  client_id?: string;
  project_id?: string;

  splitPaymentsBy?: string;
  splitPayments?: boolean;
  depositPercent?: string;
  discountPercent?: string;

  list_grid_request_id?: string;
  stripeCustomerId?: string;
  groupCart?: boolean;
  addItem: (item: CartItem) => Promise<boolean>;
  removeItem: (id: string) => void;
  updateCartDetails: (details: Partial<CartState>) => void;
};

type CartState = {
  cartItems: CartItem[];
  tile_id: string;
  scope_id: string;
  client_id: string;
  splitPaymentsBy?: string;
  splitPayments?: boolean;
  depositPercent?: string;
  discountPercent?: string;
  project_id: string;
  groupCart?: boolean;
  list_grid_request_id: string;
  stripeCustomerId: string;
};

enum CartActionTypes {
  ADD_ITEM = "ADD_ITEM",
  REMOVE_ITEM = "REMOVE_ITEM",
  UPDATE_DETAILS = "UPDATE_DETAILS"
}

type CartAction = {
  type: CartActionTypes;
  payload?: any;
};

type CartReducerAction = CartAction;

const cartReducer = (state: CartState, action: CartReducerAction): CartState => {
  switch (action.type) {
    case CartActionTypes.ADD_ITEM:
      const newState = { ...state, cartItems: [...state.cartItems, action.payload] };
      return newState;
    case CartActionTypes.UPDATE_DETAILS:
      return {
        ...state,
        tile_id: action.payload.tile_id,
        scope_id: action.payload.scope_id,
        client_id: action.payload.client_id,
        project_id: action.payload.project_id,
        splitPaymentsBy: action.payload.splitPaymentsBy,
        splitPayments: action.payload.splitPayments,
        depositPercent: action.payload.depositPercent,
        discountPercent: action.payload.discountPercent,
        groupCart: action.payload.groupCart,
        list_grid_request_id: action.payload.list_grid_request_id,
        stripeCustomerId: action.payload.stripeCustomerId
      };
    case CartActionTypes.REMOVE_ITEM:
      return { ...state, cartItems: state.cartItems.filter((item) => item.id !== action.payload) };
    default:
      return state;
  }
};

export const CartContext = createContext<CartContextType>({
  cartItems: [],
  tile_id: "",
  scope_id: "",
  client_id: "",
  project_id: "",
  list_grid_request_id: "",
  stripeCustomerId: "",
  groupCart: undefined,
  addItem: () => Promise.resolve(false),
  removeItem: () => {},
  updateCartDetails: () => {}
});

const CartProviderComponent = ({ children }: { children: ReactNode }) => {
  const loadInitialState = (): CartState => {
    let savedCartItems = [];
    let tile_id = "";
    let scope_id = "";
    let project_id = "";
    let list_grid_request_id = "";
    let stripeCustomerId = "";

    if (typeof window !== "undefined") {
      savedCartItems = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart") || "[]") : [];
      tile_id = localStorage.getItem("tile_id") || "";
      scope_id = localStorage.getItem("scope_id") || "";
      project_id = localStorage.getItem("project_id") || "";
      list_grid_request_id = localStorage.getItem("list_grid_request_id") || "";
      stripeCustomerId = localStorage.getItem("stripeCustomerId") || "";
    }

    return {
      cartItems: savedCartItems,
      tile_id,
      scope_id,
      client_id: "",
      project_id,
      list_grid_request_id,
      stripeCustomerId,
      groupCart: undefined
    };
  };

  const [cartState, dispatch] = useReducer(cartReducer, loadInitialState());
  const [itemAddedCallback, setItemAddedCallback] = useState<(() => void) | null>(null);

  useEffect(() => {
    const savedCartItems = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart") || "[]") : [];
    const tile_id = localStorage.getItem("tile_id") || "";
    const scope_id = localStorage.getItem("scope_id") || "";
    const project_id = localStorage.getItem("project_id") || "";
    const list_grid_request_id = localStorage.getItem("list_grid_request_id") || "";
    const stripeCustomerId = localStorage.getItem("stripeCustomerId") || "";

    console.log("Loaded cart items from localStorage:", savedCartItems); // This will log the loaded cart items

    dispatch({
      type: CartActionTypes.UPDATE_DETAILS,
      payload: {
        cartItems: savedCartItems,
        tile_id,
        scope_id,
        project_id,
        list_grid_request_id,
        stripeCustomerId
      }
    });
    console.log("State after loading from localStorage: ", cartState); // Added logging
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartState.cartItems));
    localStorage.setItem("tile_id", cartState.tile_id);
    localStorage.setItem("scope_id", cartState.scope_id);
    localStorage.setItem("project_id", cartState.project_id);
    localStorage.setItem("list_grid_request_id", cartState.list_grid_request_id);
    localStorage.setItem("stripeCustomerId", cartState.stripeCustomerId);
  }, [cartState]);

  const addItem = (item: CartItem) => {
    return new Promise<boolean>((resolve) => {
      console.log("Attempting to add item: ", item); // Log item being added
      dispatch({ type: CartActionTypes.ADD_ITEM, payload: item });
      resolve(true); // Resolve the promise here
    });
  };
  useEffect(() => {
    // This effect runs when cartState.cartItems changes
    if (itemAddedCallback) {
      itemAddedCallback();
      setItemAddedCallback(null); // Reset the callback after calling it
    }
  }, [cartState.cartItems, itemAddedCallback]);

  const updateCartDetails = (details: Partial<CartState>) => {
    dispatch({ type: CartActionTypes.UPDATE_DETAILS, payload: details });
  };

  const removeItem = (id: string) => {
    dispatch({ type: CartActionTypes.REMOVE_ITEM, payload: id });
  };

  const api = useMemo(
    () => ({
      cartItems: cartState.cartItems,
      tile_id: cartState.tile_id,
      scope_id: cartState.scope_id,
      project_id: cartState.project_id,
      list_grid_request_id: cartState.list_grid_request_id,
      stripeCustomerId: cartState.stripeCustomerId,
      addItem,
      groupCart: cartState.groupCart,
      updateCartDetails,
      removeItem
    }),
    [cartState]
  );

  return (
    <CartContext.Provider value={api}>
      <ModalProvider>{children}</ModalProvider>
    </CartContext.Provider>
  );
};
export const CartProvider = React.memo(CartProviderComponent);
