import React from "react";
import { formatCurrency } from "utils/format";
import { Product } from "../../types";
import { DialogListGrid } from "../../Dialog";

interface CartSummaryProps {
  cartItems: Product[];
}

const CartSummary: React.FC<CartSummaryProps> = ({ cartItems }) => {
  const subtotal = cartItems.reduce((acc, product) => acc + (product.original || 0), 0);
  const savings = cartItems.reduce(
    (acc, product) =>
      acc + ((product.original || 0) - (product.grandTotal !== undefined ? product.grandTotal : product.total || 0)),
    0
  );

  const total = cartItems.reduce(
    (acc, product) => acc + (product.grandTotal !== undefined ? product.grandTotal : product.total || 0),
    0
  );
  const dueToday = cartItems.reduce((acc, product) => acc + (product.total || 0), 0);
  const showDueToday = cartItems.some((item) => item.grandTotal !== undefined);

  return (
    <div className="my-6 flex-shrink-0 font-matter" id="cartSummary">
      <div className="text-neutral-500 align-center mx-auto grid grid-cols-1 divide-y-2 px-4 text-sm lg:w-[80%] lg:px-0 ">
        {subtotal > 0 && (
          <div className="flex items-center justify-between py-5 align-middle">
            <p className="text-base font-medium leading-none text-[#717171]">Regular Price</p>
            <p className="text-right text-base font-medium leading-none">${formatCurrency(subtotal.toString())}</p>
          </div>
        )}

        {savings > 0 && (
          <div className="flex items-center justify-between py-5 align-middle">
            <p className="text-base font-medium leading-none text-[#717171]">Saved</p>
            <p className="text-right text-base font-medium leading-none text-red-700">
              -${formatCurrency(savings.toString())}
            </p>
          </div>
        )}
        <div className="flex items-center justify-between py-5 align-middle">
          <p className="text-base font-medium leading-none text-[#717171]">Total</p>
          <p className="text-right text-base font-medium leading-none">${formatCurrency(total.toString())}</p>
        </div>

        {showDueToday && (
          <div className="flex justify-between py-5 align-middle">
            <p className="text-base font-medium leading-none">
              Due Today <DialogListGrid />
            </p>
            <p className="text-right text-base font-medium leading-none text-primary-700">
              ${formatCurrency(dueToday.toString())}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartSummary;
