import React from "react";
import { CartItem } from "../../types"; // Ensure this is the correct type
import BigCard from "../Cards/BigCard";
import GroupCard from "../Cards/GroupCard";

interface CartItemsProps {
  cartItems: CartItem[];
  useGroupCard: boolean;
  groupCardKey?: string;
}

const CartItems: React.FC<CartItemsProps> = ({ cartItems, groupCardKey }) => {
  return (
    <ul className="flex-grow overflow-auto pt-2 lg:space-y-4">
      {cartItems.map((cartItem) =>
        cartItem.useGroupCard ? (
          <GroupCard key={cartItem.id} cartItem={cartItem} groupCardKey={groupCardKey} />
        ) : (
          <BigCard key={cartItem.id} cartItem={cartItem} />
        )
      )}
    </ul>
  );
};
export default CartItems;
