import React from "react";
import { RotateCw } from "lucide-react";
import { Button } from "components/ui/button";
import { SheetFooter } from "components/ui/sheet";

type StepLayoutProps = {
  children: React.ReactNode;
  onNext: () => void;
  isLoading: boolean;
  buttonText?: string;
};

const StepLayout: React.FC<StepLayoutProps> = ({ children, onNext, isLoading, buttonText = "Next" }) => (
  <>
    <div id="stepLayout" className="stuff flex h-full flex-grow flex-col overflow-auto">
      {children}
    </div>
    <SheetFooter className="mt-auto flex-shrink-0">
      <Button
        type="button"
        variant="brand"
        size="checkout"
        onClick={onNext}
        className={`!min-h-[70px] !rounded-none ${isLoading ? "!bg-[#073937] !opacity-50" : ""}`}
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <RotateCw className="mr-2 h-4 w-4 animate-spin" />
            Loading...
          </>
        ) : (
          buttonText
        )}
      </Button>
    </SheetFooter>
  </>
);

export default StepLayout;
