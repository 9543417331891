// hooks/useCheckout.ts
import { useContext, useState } from "react";
import { toast } from "sonner";

import { CartContext } from "context/CartContext";
const AIRSTUDIO_CLIENT_API_TOKEN = process.env.NEXT_PUBLIC_AIRSTUDIO_API_TOKEN || "";

export const useCreatePaymentIntent = () => {
  const cartContext = useContext(CartContext);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!cartContext) {
    throw new Error("CartContext not found");
  }

  const createPaymentIntent = () => {
    setIsLoading(true);
    return new Promise<string>((resolve, reject) => {
      const body = {
        items: cartContext.cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
          total: item.total,
          cost_line_ids: item.cost_line_ids
        })),
        stripeCustomerId: cartContext.stripeCustomerId,
        tile_id: cartContext.tile_id,
        scope_id: cartContext.scope_id,
        project_id: cartContext.project_id,
        list_grid_request_id: cartContext.list_grid_request_id
      };

      fetch("/api/payments/p_create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: AIRSTUDIO_CLIENT_API_TOKEN },
        body: JSON.stringify(body)
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Payment Intent POST failed with status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setClientSecret(data.clientSecret);
          resolve(data.clientSecret);
        })
        .catch((error) => {
          console.error("Error creating payment intent:", error);
          toast.error("Error creating payment intent.", {
            position: "bottom-left",
            duration: 5000
          });
          reject(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return { createPaymentIntent, clientSecret, isLoading, setClientSecret };
};
