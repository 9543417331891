// components/Tiles/components/ListGrid/Cart/Modal/Checkout.tsx
"use client";
import React, { useContext, useEffect, useRef, RefObject } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { CartContext } from "context/CartContext";
import { Button } from "components/ui/button";
import { Product } from "../../types";

import CartSummary from "./cSummary";

type CheckoutFormProps = {
  formRef: RefObject<HTMLFormElement>;
  setIsLoading: (isLoading: boolean) => void; // Function to set loading state
};

export default function CheckoutForm({ formRef, setIsLoading }: CheckoutFormProps) {
  console.log("Rendering CheckoutFormSidebar");

  const stripe = useStripe();
  const elements = useElements();
  const cartContext = useContext(CartContext);
  const [message, setMessage] = React.useState<string | null>(null);

  console.log("Stripe object:", stripe);
  console.log("Elements object:", elements);

  if (!cartContext) {
    throw new Error("CartContext not found");
  }

  useEffect(() => {
    console.log("useEffect - Stripe instance:", stripe);
    if (!stripe) {
      console.log("Stripe is not loaded yet.");
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      } else {
        setMessage("Payment Intent could not be retrieved.");
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("handleSubmit triggered");

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://client.buildappeal.com/tiles/order-confirmation"
      }
    });

    if (error) {
      console.log("Error during payment confirmation:", error); // Added logging
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message || "An error occurred.");
      } else {
        setMessage("An unexpected error occurred.");
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs" as const
  };

  return (
    <>
      <div className="flex-grow overflow-auto lg:space-y-4">
        <form ref={formRef} id="payment-form" className="mt-2" onSubmit={handleSubmit}>
          {/* PaymentElement will grow to take available space, pushing summary and button down */}
          <div className="mx-auto w-full  flex-grow">
            <div className="mx-auto w-[80%] ">
              {stripe && elements && <PaymentElement id="payment-element" options={paymentElementOptions} />}
            </div>
          </div>
          {message && <div id="payment-message" className="px-12"></div>}
        </form>
      </div>
      <CartSummary cartItems={cartContext.cartItems.map((item) => ({ ...item })) as Product[]} />
    </>
  );
}
