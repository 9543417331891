import * as React from "react";
import { useMediaQuery } from "usehooks-ts";
import { HelpCircle } from "lucide-react";
import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from "components/ui/drawer";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";

const paymentDetails = [
  {
    title: "desposit",
    amount: "50%",
    when: "upon project completion"
  },
  {
    title: "final payment",
    amount: "100%",
    when: "upon project completion"
  }
];

export function DialogListGrid() {
  const [open, setOpen] = React.useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <HelpCircle size={13} className="inline-flex" />
        </DialogTrigger>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>How Payment Works</DialogTitle>
          </DialogHeader>
          <PaymentDetails />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <HelpCircle size={13} className="inline-flex" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>How payment works</DrawerTitle>
        </DrawerHeader>
        <PaymentDetails className="px-4" />
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <Button variant="outline">Close</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

function PaymentDetails({ className }: React.ComponentProps<"div">) {
  return (
    <div className={cn("flex", className)}>
      {paymentDetails.map((detail, index) => (
        <div key={index} className="flex-1">
          <h3>{detail.title}</h3>
          <p>{detail.amount}</p>
          <p>{detail.when}</p>
        </div>
      ))}
    </div>
  );
}
