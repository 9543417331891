import React from "react";
import { Check } from "lucide-react";
import { FadeIn } from "components/ui/fade";
import { ScrollArea } from "components/ui/scroll-area";

type PaymentScheduleProps = {
  schedule: {
    title?: string;
    subTitle?: string;
    deliverables?: string[];
    paymentAmount?: string;
    paymentTrigger?: string;
  }[];
};

const PaymentSchedule: React.FC<PaymentScheduleProps> = ({ schedule }) => {
  return (
    <div className="mx-auto mb-[150px] flex h-full w-[80%] max-w-[80%] flex-col space-y-4">
      {schedule.map((item, index) => (
        <FadeIn key={index} className="flex items-start space-x-4">
          <div className="z-10 flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-[#f5f1e4] text-[#222]">
            {index + 1}
          </div>
          <div className="flex flex-col space-y-1">
            {item.title ? <h3 className=" pr-6 font-heading text-lg font-medium">{item.title}</h3> : null}
            {item.subTitle ? <span className="text-primary-500 pb-2 text-base">{item.subTitle}</span> : null}

            <ul className="list-none pl-0 text-gray-500">
              {item.deliverables?.map((deliverable, deliverableIndex) => (
                <li key={deliverableIndex}>
                  <FadeIn className="flex items-center gap-x-3">
                    <span className="mt-1 flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-brandlight">
                      <Check strokeWidth={1.3} size={13} />
                    </span>
                    {deliverable}
                  </FadeIn>
                </li>
              ))}
            </ul>
            {item.paymentAmount || item.paymentTrigger ? (
              <FadeIn className="flex items-center gap-x-4 px-4 pb-4 pt-8 lg:px-0">
                <span className="flex gap-x-4 rounded-lg  bg-[#f5f1e4] px-4 py-2.5">
                  {item.paymentAmount ? (
                    <p className="text-base leading-none text-[#565d5a]">
                      Amount <span className="pl-1 text-primary-700 ">{item.paymentAmount}</span>
                    </p>
                  ) : null}

                  {item.paymentTrigger ? (
                    <p className="text-base leading-none text-[#565d5a] ">
                      Due <span className="pl-1 text-primary-700 ">{item.paymentTrigger}</span>
                    </p>
                  ) : null}
                </span>
              </FadeIn>
            ) : null}
          </div>
        </FadeIn>
      ))}
    </div>
  );
};

export default PaymentSchedule;
