"use client";
import React, { useContext, useState } from "react";
import { CartContext } from "context/CartContext";
import { useCreatePaymentIntent } from "hooks/useCheckout";
import { ModalContext } from "context/modalContext";

import { Product, CartItem } from "../../types";
import CartItems from "./cItems";
import CartSummary from "./cSummary";
import StepLayout from "./StepLayout";

const StepCart: React.FC = () => {
  const cartContext = useContext(CartContext);
  const modalContext = useContext(ModalContext);
  const { createPaymentIntent, clientSecret, isLoading, setClientSecret } = useCreatePaymentIntent();
  const [useGroupCard, setUseGroupCard] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!cartContext || !modalContext) {
    throw new Error("Context not found");
  }

  const { cartItems } = cartContext;
  const { setCurrentStep } = useContext(ModalContext);

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    if (isLoading) return;

    if (!clientSecret) {
      const secret = await createPaymentIntent();
      if (secret) {
        setClientSecret(secret);
        modalContext.setShowCheckoutForm(true);
        modalContext.setClientSecret(secret);
      }
    }

    // Send cart items to webhook
    if (cartItems && cartItems.length > 0) {
      //   console.log("Cart items before sending to webhook:", cartItems);

      const payload = {
        cartContents: cartItems,
        cartAttributes: {
          tile_id: cartContext.tile_id,
          scope_id: cartContext.scope_id,
          project_id: cartContext.project_id,
          list_grid_request_id: cartContext.list_grid_request_id,
          stripeCustomerId: cartContext.stripeCustomerId,
          splitPaymentsBy: cartContext.splitPaymentsBy,
          splitPayments: cartContext.splitPayments,
          depositPercent: cartContext.depositPercent,
          discountPercent: cartContext.discountPercent,
          groupCart: cartContext.groupCart
        }
      };

      try {
        const response = await fetch("https://hook.us1.make.com/j3v1hv1pigkbensfl2oynbtmhvkyoltg", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const paymentScheduleData = await response.json();
        modalContext.setPaymentSchedule(paymentScheduleData.paymentSchedule);

        const nextStep = paymentScheduleData.nextStep;
        if (nextStep) {
          setCurrentStep(parseInt(nextStep, 10));
        } else {
          // Default to step 1 if no nextStep is present in the response body
          setCurrentStep(2);
        }
      } catch (error) {
        console.error("Error sending cart to webhook:", error);
      }
    } else {
      modalContext.setShowCheckoutForm(true);
      setCurrentStep(1);
    }

    setIsSubmitting(false);
  };

  return (
    <StepLayout onNext={handleSubmit} isLoading={isLoading || isSubmitting}>
      <CartItems cartItems={cartItems.filter((item) => item.id !== undefined)} useGroupCard={useGroupCard} />
      <CartSummary cartItems={cartItems.map((item) => ({ ...item, id: item.id ?? "" })) as Product[]} />
    </StepLayout>
  );
};

export default StepCart;
