import React, { useContext } from "react";
import Image from "next/image";
import { CartContext } from "context/CartContext";
import { CartItem } from "../../types";

interface GroupCardProps {
  cartItem: CartItem;
  groupCardKey?: string; // Optional prop for user to decide which key to use
}

const GroupCard: React.FC<GroupCardProps> = ({ cartItem }) => {
  const { groupCart } = useContext(CartContext);

  const getNameValue = (itemObject: any) => {
    const itemArray = Object.values(itemObject);
    // Use the groupCardKey from the cartItem if available, otherwise default to "Category"
    const groupCardKey = cartItem.groupCardKey || "Category";
    const nameEntry = itemArray.find((entry: any) => entry.key === groupCardKey);
    // @ts-ignore
    return nameEntry ? nameEntry.value : "Unnamed Item";
  };

  return (
    <li className="relative mx-auto flex w-full flex-col bg-white lg:w-[80%]">
      {groupCart ? null : (
        <div className="absolute -right-1 -top-1 z-50">{/* Assuming you have a RemoveFromCart component */}</div>
      )}
      <div className="relative flex w-full flex-row justify-between">
        <div className="z-30 flex flex-row space-x-4">
          <div className="relative h-32 w-32 cursor-pointer overflow-hidden border-neutral-300">
            {cartItem.item?.image && (
              <Image
                src={cartItem.item.image}
                alt={cartItem.item.name || ""}
                fill
                style={{
                  objectFit: "cover"
                }}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col px-4 pb-2 pt-6 text-base">
            <span className="pb-2 text-sm font-medium leading-tight">{cartItem.name}</span>
            <span className="pb-2 text-sm font-medium leading-tight">{cartItem?.description}</span>
            {/* Render the bullet point list of items */}
            <ul className="list-disc pl-5">
              {cartItem.items?.map((item, index) => (
                <li key={index} className="text-sm">
                  {getNameValue(item)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default GroupCard;
