import React, { useContext } from "react";
import Image from "next/image";
import { CartContext, CartItem } from "context/CartContext"; // Import CartItem from CartContext
import { RemoveFromCart } from "../removeFromCart";

interface BigCardProps {
  cartItem: CartItem;
}

const BigCard: React.FC<BigCardProps> = ({ cartItem }) => {
  const { groupCart, removeItem } = useContext(CartContext); // Use removeItem from CartContext

  return (
    <li className="relative mx-auto flex w-full flex-col bg-white lg:w-[80%]">
      {!groupCart && <div className="absolute -right-1 -top-1 z-50"></div>}
      <div className="relative flex w-full flex-row justify-between">
        <div className="z-30 flex flex-row space-x-4">
          <div className="relative h-32 w-32 cursor-pointer overflow-hidden border-neutral-300">
            {cartItem.image && <Image src={cartItem.image} alt={cartItem.name || ""} layout="fill" objectFit="cover" />}
          </div>
          <div className="flex flex-1 flex-col px-4 pb-2 pt-6 text-base">
            <span className="pb-2 text-sm font-medium leading-tight">{cartItem.name}</span>
            <p className="mb-4 text-sm leading-tight text-[#717171]">{cartItem.description}</p>
            {/*  <div className="flex w-full max-w-fit items-center justify-between">
              {cartItem.grandTotal !== undefined && (
                <p className="mr-2 justify-start space-y-2 text-right text-sm">${cartItem.grandTotal.toFixed(2)}</p>
              )}
              {cartItem.total !== undefined && cartItem.total !== 0 && (
                <p className="mr-2 justify-start space-y-2 text-right text-sm">${cartItem.total.toFixed(2)}</p>
              )}
              {cartItem.original !== undefined && cartItem.original !== 0 && (
                <p className="justify-end space-y-2 text-right text-sm line-through">${cartItem.original.toFixed(2)}</p>
              )}
            </div>
              */}
          </div>
        </div>
      </div>
    </li>
  );
};

export default BigCard;
