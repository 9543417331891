// components/Tiles/components/ListGrid/Cart/Modal/modal.tsx

import { ForwardedRef, forwardRef, useContext, useRef } from "react";
import { SheetContent, SheetHeader } from "components/ui/sheet";
import { CartContext } from "context/CartContext";
import { ModalContext } from "../../../../../../context/modalContext";
import { CartModalProps } from "../../types";
import StepCart from "./stepCart";
import StepCheckout from "./stepCheckout";
import StepNavigation from "./stepNavigation";
import StepSchedule from "./stepSchedule";

// eslint-disable-next-line react/display-name
const CartModal = forwardRef<HTMLDivElement, CartModalProps>(
  ({ directCheckout, hideStepNavigation, ...props }, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    const { cartItems } = useContext(CartContext);
    const { currentStep, setCurrentStep } = useContext(ModalContext);

    if (!cartItems) {
      throw new Error("CartContext not found");
    }

    const formRef = useRef<HTMLFormElement>(null);
    const onNext = () => {
      console.log("onNext called", currentStep);
      if (currentStep === 2) {
        // Assuming 2 is the payment step
        if (formRef.current) {
          console.log("Form exists, dispatching submit event");
          const submitEvent = new Event("submit", { cancelable: true, bubbles: true });
          formRef.current.dispatchEvent(submitEvent);
        }
      } else {
        setCurrentStep(currentStep + 1);
      }
    };
    const steps = [
      { component: <StepCart />, title: "Cart" },
      { component: <StepSchedule />, title: "Schedule" },
      { component: <StepCheckout formRef={formRef} onNext={onNext} />, title: "Payment Details" }
    ];

    const currentStepComponent = steps[currentStep]?.component || <StepCart />;
    const currentStepTitle = steps[currentStep]?.title || "Cart";

    return (
      <SheetContent
        {...props}
        ref={forwardedRef}
        className="sheetcontent lg-w-[50vw] fixed bottom-0 right-0 top-0 flex h-full max-h-screen w-full  min-w-full flex-col  border-none bg-[#FAF8F5] p-0 font-matter lg:min-w-[50vw]"
        style={{ height: "100%" }}
      >
        <SheetHeader className=" sheetheader h-[150px] min-h-[100px] items-center justify-center ">
          <p className="text-left text-2xl font-medium">{currentStepTitle}</p>
          {!hideStepNavigation && !!cartItems.length && <StepNavigation />}
        </SheetHeader>
        {cartItems.length === 0 ? (
          <div className="mt-20 flex w-full flex-col items-center justify-center overflow-hidden">
            <p className="mt-6 text-center text-2xl font-bold text-primary-700">Your cart is empty.</p>
          </div>
        ) : (
          currentStepComponent
        )}
      </SheetContent>
    );
  }
);

export default CartModal;
