"use client";

import React, { useContext } from "react";
import { ModalContext } from "context/modalContext";
import PaymentSchedule from "./PaymentSchedule";
import StepLayout from "./StepLayout";

const StepSchedule: React.FC = () => {
  const { currentStep, paymentSchedule, setCurrentStep } = useContext(ModalContext);

  const handleContinueToPayment = () => {
    // Set the next step here
    setCurrentStep(2); // Assuming 2 is the next step after StepSchedule
  };

  return (
    <StepLayout onNext={handleContinueToPayment} isLoading={false}>
      {paymentSchedule ? (
        <PaymentSchedule schedule={paymentSchedule} />
      ) : (
        <p>Loading payment schedule...</p> // Replace this with your loading state or fallback UI
      )}
    </StepLayout>
  );
};
export default StepSchedule;
